.UpdateRow {
    display: grid;

    grid-template-columns: auto auto 1fr auto;
    grid-template-areas:
        "gamelog gamelog gamelog gamelog timestamp"
        "batter batter . . ."
        "score . . atbat atbat";

    @apply gap-x-2 gap-y-1 sm:gap-2 p-2 items-center border-b border-gray-300 dark:border-gray-700;
}

.TemporalRow {
    display: grid;
    
    grid-template-columns: auto auto 1fr;
    grid-template-areas: "icon delta .";
}

.SunSunPressure {
    padding: 0.5rem;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
        "delta delta"
	"pressure gauge";

    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    border-bottom-width: 1px;
}

.SunSunPressure > * {
    grid-area: delta;
}

.pressure-value {
    grid-area: pressure;
    justify-self: end;
}

.progress-bar__outer {
    grid-area: gauge;
    background: linear-gradient(90deg, rgba(251,205,98,1) 0%, rgba(212,76,20,1) 100%);
    width: 8.625rem;
    border-radius: 0.3rem;
}

.progress-bar {
    height: 100%;
    background-color: #007bff;
    background: linear-gradient(90deg, #10457f 0%, #2379a6 100%);
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.TemporalRow-Icon {
    grid-area: icon;
}

.TemporalRow-Delta {
    grid-area: delta;
    white-space: pre-line;
}

@screen sm {
    .UpdateRow {
        grid-template-areas:
            "gamelog gamelog gamelog gamelog timestamp"
            "score batter . atbat atbat";
    }

    .SunSunPressure {
	grid-template-columns: 1fr fit-content auto;
	grid-template-areas: "delta pressure gauge";
    }
}

@screen lg {
    .UpdateRow {
        grid-template-columns: auto auto 1fr auto auto;
        grid-template-areas: "timestamp score gamelog batter atbat";
    }
}

.UpdateRow-Highlight {
    @apply bg-yellow-200 dark:bg-gray-900;
}


.UpdateRow-Timestamp {
    grid-area: timestamp;
    @apply text-gray-700 dark:text-gray-300;
}

.UpdateRow-Score {
    grid-area: score;
    @apply font-semibold bg-gray-200 dark:bg-gray-800;
}

.UpdateRow-GameLog {
    grid-area: gamelog;
    white-space: pre-line;
}

.UpdateRow-Batter {
    grid-area: batter;
    @apply text-sm bg-gray-200 dark:bg-gray-800 rounded px-2 py-1 inline-flex items-center justify-center;
}

.UpdateRow-AtBat {
    grid-area: atbat;
    @apply space-x-2 flex flex-row items-center;
}