.Player-Info {
    border-bottom: 1px solid #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 40px 20px;
}
.Player-Info-Line {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 40px;
}
.Player-Info-Line:nth-child(2n) {
    background: #000;
}
.Player-Info-Line-Header {
    width: 180px;
    font-weight: 700;
}
.Player-Info-Line-Body,
.Player-Info-Line-Header {
    font-family: "Lora", "Courier New", monospace, serif;
}
.Player-Info-Bio,
.Player-Info-Stats {
    padding: 0;
    background: rgba(30, 30, 30, 0.64);
}
.Player-Info-Items {
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.Player-Info-Items-Box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: auto;
    min-width: 150px;
    height: 80px;
    margin: 5px;
    padding: 10px 0;
    background: #111;
    border-radius: 5px;
}
.Player-Info-Items-Header {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
}
.Player-Info-Items-Details {
    display: flex;
    flex-direction: row;
}
.Player-Info-Items-Name {
    font-family: "Lora", "Courier New", monospace, serif;
}
.Player-Header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Lora", "Courier New", monospace, serif;
    font-size: 24px;
    width: 100%;
}
.Player-Header-Bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.Player-Header-Name {
    font-family: "Lora", "Courier New", monospace, serif;
    font-size: 24px;
}

.Player-Content {
    padding: 20px 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Player-VibeLine {
    display: flex;
    flex-direction: row;
}
.Player-VibeLine-Arrow {
    font-size: 22px;
    width: 16px;
}
.Player-VibeLine-Text {
    padding: 0 5px;
    font-family: "Lora", "Courier New", monospace, serif;
}
.Player-Vibe-MostExcellent {
    color: #15d400;
}
.Player-Vibe-Excellent {
    color: #5de04f;
}
.Player-Vibe-Quality {
    color: #8fdb88;
}
.Player-Vibe-Neutral {
    color: #d1d1d1;
}
.Player-Vibe-LessThanIdeal {
    color: #d97373;
}
.Player-Vibe-FarLessThanIdeal {
    color: #de3c3c;
}
.Player-Vibe-Terrible {
    color: #e00000;
}
.Player-Soulscream {
    height: auto;
    max-width: 240px;
    border-radius: 5px;
}
.Player-Soulscream-Body {
    height: auto;
    font-size: 16px;
    font-weight: 700;
    font-family: "Lora", "Courier New", monospace, serif;
    font-style: italic;
    color: red;
    word-wrap: break-word;
}
.Player-Team-Line {
    display: flex;
}
.Player-Team-Logo {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}
.Player-Team-Name {
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-family: "Lora", "Courier New", monospace, serif;
    font-size: 18px;
}