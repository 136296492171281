.BlaseRunners {
    @apply text-2xl mr-2;
    line-height: 1.5rem;
    letter-spacing: -10px;
}

.BlaseRunners-Base-2,
.BlaseRunners-Base-0 {
    @apply relative;
    top: 3px;
}

.BlaseRunners-Base-1 {
    @apply relative;
    top: -7px;
}
