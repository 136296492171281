@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* See blaseball/select.ts :( */
    :root {
        --theme-black: theme("colors.black");
        --theme-white: theme("colors.white");
        --theme-gray-100: theme("colors.gray.100");
        --theme-gray-200: theme("colors.gray.200");
        --theme-gray-300: theme("colors.gray.300");
        --theme-gray-400: theme("colors.gray.400");
        --theme-gray-500: theme("colors.gray.500");
        --theme-gray-600: theme("colors.gray.600");
        --theme-gray-700: theme("colors.gray.700");
        --theme-gray-800: theme("colors.gray.800");
        --theme-gray-900: theme("colors.gray.900");
        --theme-blue-600: theme("colors.blue.600");
        --theme-blue-500: theme("colors.blue.500");
        --theme-blue-300: theme("colors.blue.300");
        --theme-blue-100: theme("colors.blue.100");
        --theme-red-600: theme("colors.red.600");
        --theme-red-300: theme("colors.red.300");
    }

    body {
        @apply bg-white text-gray-900 antialiased min-h-screen;
    }

    :root.mode-dark {
        --theme-black: theme("colors.white");
        --theme-white: theme("colors.black");
        --theme-gray-100: theme("colors.gray.900");
        --theme-gray-200: theme("colors.gray.800");
        --theme-gray-300: theme("colors.gray.700");
        --theme-gray-400: theme("colors.gray.600");
        --theme-gray-500: theme("colors.gray.500");
        --theme-gray-600: theme("colors.gray.400");
        --theme-gray-700: theme("colors.gray.300");
        --theme-gray-800: theme("colors.gray.200");
        --theme-gray-900: theme("colors.gray.100");
        --theme-blue-600: theme("colors.blue.400");
        --theme-blue-500: theme("colors.blue.500");
        --theme-blue-300: theme("colors.blue.700");
        --theme-blue-100: theme("colors.blue.900");
        --theme-red-600: theme("colors.red.200");
        --theme-red-300: theme("colors.red.700");
    }

    :root.mode-dark body {
        @apply bg-black text-gray-100;
    }

    strong {
        @apply font-semibold;
    }

    label {
        @apply cursor-pointer;
    }

    a:hover {
        @apply underline;
    }

    /* Twemoji support */
    img.emoji {
        display: inline-block;
        height: 1em;
        width: 1em;
        margin: 0 .05em 0 .1em;
        vertical-align: -0.15em;
    }
}

@layer components {
    .tag {
        @apply text-sm rounded px-2 py-1 inline-flex items-center justify-center;
    }

    .tag-sm {
        @apply text-sm rounded px-2 inline-flex items-center justify-center;
    }
}
